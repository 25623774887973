<template>
  <div>
    <v-container class="app-context" fluid v-if="!listOnOff">
      <div class="context flex">
        <v-row>
          <v-col
            cols="12"
            class="text-center"
            style="font-size: 32px; font-weight: 500; margin-top: 50px"
          >
            쿠폰 등록
          </v-col>
          <v-col cols="12">
            <v-row justify="center" style="margin-top: 50px">
              <v-col cols="9" class="text-center couponBI">
                <v-row class="coupon-box">
                  <v-col cols="12" class="text-left">
                    <v-icon x-large dark style="position: relative; top: -3px">
                      mdi-ticket
                    </v-icon>
                    <span
                      style="color: white; font-size: 25px; margin-left: 10px"
                    >
                      쿠폰번호를 입력해 주세요
                    </span>
                  </v-col>
                  <v-col cols="10" class="coupon-reg-col">
                    <div class="s-input-wrap">
                      <input
                        id="input"
                        type="text"
                        v-model="couponStr"
                        @keyup.enter="registCoupon()"
                      />
                    </div>
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      color="#ff7334"
                      dark
                      shaped
                      rounded
                      width="130px"
                      height="55px"
                      style="font-weight: bold; font-size: 16px"
                      @click="registCoupon()"
                    >
                      쿠폰 등록
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <v-container class="scroll-body app-context" fluid v-if="listOnOff">
      <v-col
        v-if="listOnOff"
        cols="12"
        class="text-center"
        style="font-size: 25px; font-weight: 500; margin-top: 50px"
      >
        사용 가능한 쿠폰
      </v-col>
      <div class="scroll-content2">
        <v-row no-gutters justify="center" style="margin-top: 50px">
          <v-col class="text-center">
            <v-row>
              <v-col
                cols="3"
                class="text-center"
                style="
                  min-width: 120px;
                  color: #9e9e9e;
                  font-family: 'SUIT';
                  font-size: 19px;
                "
              >
                쿠폰명
              </v-col>
              <v-col
                cols="2"
                class="text-center"
                style="
                  min-width: 120px;
                  color: #9e9e9e;
                  font-family: 'SUIT';
                  font-size: 19px;
                "
              >
                쿠폰번호
              </v-col>
              <v-col
                cols="3"
                class="text-center"
                style="
                  min-width: 120px;
                  color: #9e9e9e;
                  font-family: 'SUIT';
                  font-size: 19px;
                "
              >
                쿠폰기간
              </v-col>
              <v-col
                cols="2"
                class="text-center"
                style="
                  min-width: 120px;
                  color: #9e9e9e;
                  font-family: 'SUIT';
                  font-size: 19px;
                "
              >
                쿠폰등록일자
              </v-col>
              <v-col
                cols="2"
                class="text-center"
                style="
                  min-width: 120px;
                  color: #9e9e9e;
                  font-family: 'SUIT';
                  font-size: 19px;
                "
              >
                쿠폰금액
              </v-col>
            </v-row>
            <v-row v-for="(listItem, index) in list" :key="index + 'A'">
              <v-col cols="3">
                <span style="font-family: 'SUIT'; font-size: 18px">{{
                  listItem.cponNm
                }}</span>
              </v-col>
              <v-col cols="2">
                <span style="font-family: 'SUIT'; font-size: 18px">{{
                  listItem.cponId
                }}</span>
              </v-col>
              <v-col cols="3">
                <span style="font-family: 'SUIT'; font-size: 18px"
                  >{{ $moment(listItem.validStrDt).format("YYYY-MM-DD") }} ~
                  {{ $moment(listItem.validEndDt).format("YYYY-MM-DD") }}</span
                >
              </v-col>
              <v-col cols="2">
                <span style="font-family: 'SUIT'; font-size: 18px">{{
                  $moment(listItem.regDt).format("YYYY-MM-DD")
                }}</span>
              </v-col>
              <v-col cols="2">
                <span style="font-family: 'SUIT'; font-size: 18px">{{
                  listItem.cponAmt.toString().replaceAll("-", "")
                }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12" class="text-center" style="margin-bottom: 80px">
          <v-btn
            width="240px"
            height="55px"
            rounded
            color="#146f83"
            :class="listOnOff ? 'btnon' : ''"
            dark
            @click="listOnOffAction"
          >
            <span v-if="!listOnOff" style="font-size: 15px"
              >사용 가능한 쿠폰 리스트</span
            >
            <span v-if="listOnOff" style="font-size: 20px">돌아가기</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>
<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.8;
  position: absolute;
  width: 100%;
}
</style>

<script>
import AppHeader from "@/components/appHeader/AppHeaderTest";
import SideMenu from "@/components/sidemenu/SideMenu";
import DefaultPopup from "@/components/modal/DefaultPopup";
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
export default {
  components: { SideMenu, DefaultPopup, AppHeader },
  data() {
    return {
      listOnOff: false,
      popupSet: {},
      sideSet: {
        menuName: "payment",
        sideTitle: "소개 디자인",
        activeOrder: 0,
        isWritedSearch: null,
        isWritedPr: null,
      },
      couponStr: "",
      headerOption: {
        navOrder: 0,
        isMain: true,
      },
      listStatus: {
        totalCount: null, // 조회하여 가져온 총 데이터의 수
        dataOnPage: 10, // 한 페이지에 내려줄 데이터의 수
        pageCount: 5, // 한 페이지에 1~5까지 보여준다.
        totalPage: null, // 페이지네이션에 출력한 총 페이지의 수
        currentPage: 1, // 현재 페이지
        prev: null,
        next: null,
        first: null,
        last: null,
        currentPaginationList: [],
        /**
         * totalCount: 22
         * currentPage: 1
         * totalPage: 3
         * pageGroup: 1
         * last: 3
         * first: -1
         * next: 4
         * prev: -2
         */
      },
    };
  },
  created() {
    this.config();
  },
  mounted() {
    // document.querySelector(".registCoupon").classList.add("active"); //사이드메뉴 불키기
  },
  computed: {
    ...mapState("basic", ["checkPopup", "checkSearchPopup"]),
    ...mapGetters("prod", ["GET_PROD_LIST", "GET_COUPON_LIST", "GET_COUNT"]),
    ...mapGetters("admin", ["GET_PRGS_LIST", "GET_COUPON_DATA"]),
  },
  methods: {
    ...mapActions("admin", ["BRING_COUPON_DATA"]),
    ...mapActions("prod", [
      "BRING_PROD_LIST",
      "BRING_USER_COUPON_USABLE_LIST",
      "BRING_USER_COUPON_COUNT",
      "ADD_USER_COUPON",
    ]),
    ...mapMutations("basic", [
      "SET_POPUP",
      "SET_CHILD_LOADER",
      "SET_SEARCH_POPUP",
    ]),
    ...mapActions("admin", ["BRING_PRGS_LIST"]),
    initListStatus() {
      this.listStatus.totalCount = null;
      this.listStatus.dataOnPage = 10;
      this.listStatus.pageCount = 5;
      this.listStatus.totalPage = null;
      this.listStatus.currentPage = 1;
      this.listStatus.prev = null;
      this.listStatus.next = null;
      this.listStatus.first = null;
      this.listStatus.last = null;
      this.currentPaginationList = [];
    },
    async config() {
      this.currentPage = 0; // 현재 페이지
      this.initListStatus();
      this.list = [];
      this.SET_CHILD_LOADER(true);

      const searchOpt = {
        pageNo: 1,
        recordsPerPage: this.listStatus.dataOnPage,
      };
      try {
        await this.BRING_USER_COUPON_USABLE_LIST(searchOpt).then(() => {
          // console.log(this.GET_COUPON_LIST);
          this.listStatus.totalCount = this.GET_COUPON_LIST.param.totalCount;
          this.listStatus.currentPage = this.GET_COUPON_LIST.param.pageNo;
          let tempList = JSON.parse(JSON.stringify(this.GET_COUPON_LIST.list));
          setTimeout(() => {
            this.SET_CHILD_LOADER(false);
            this.list = tempList;
            // this.preParePagination();
          }, 800);
        });
      } catch (error) {
        console.log(error);
      }
    },
    async registCoupon() {
      //console.log("coupon");
      //console.log(this.couponStr);
      if (this.couponStr !== "") {
        await this.BRING_USER_COUPON_COUNT(this.couponStr).then(() => {
          // console.log("기등록 쿠폰 갯수 : ", this.GET_COUNT);
        });

        if (this.GET_COUNT > 0) {
          this.popupSet.title = "등록된 쿠폰 존재";
          this.popupSet.content =
            "이미 등록하신 쿠폰번호입니다. 쿠폰번호 및 아래 리스트를 확인하세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          this.SET_POPUP(true);
        } else {
          await this.BRING_COUPON_DATA(this.couponStr).then(() => {
            //console.log("데이터 값1", this.GET_COUPON_DATA);
            //console.log("데이터 값", this.GET_COUPON_DATA.cponNm);
          });
          if (
            this.GET_COUPON_DATA === "" ||
            this.GET_COUPON_DATA === undefined ||
            this.GET_COUPON_DATA === null ||
            this.GET_COUPON_DATA.cponNm === null ||
            this.GET_COUPON_DATA.validEndDt <
              this.$moment().format("YYYYMMDD") ||
            this.GET_COUPON_DATA.useYn === "N"
          ) {
            this.popupSet.title = "쿠폰 등록 실패";
            this.popupSet.content =
              "유효하지 않은 쿠폰번호입니다. 대소문자 구분 및 숫자를 확인하세요.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            this.SET_POPUP(true);
          } else {
            try {
              await this.ADD_USER_COUPON(this.couponStr).then(() => {
                this.SET_POPUP(true);
                this.popupSet.title = "쿠폰 등록 완료";
                this.popupSet.content = "쿠폰 등록이 완료되었습니다.";
                this.popupSet.popType = "refund";
                this.popupSet.confirmBtnText = undefined;
                this.popupSet.cancelBtnText = "확인";
              });
            } catch (error) {
              console.log(error);
              this.SET_POPUP(true);
              this.popupSet.title = "쿠폰 등록 실패";
              this.popupSet.content =
                "오류가 발생하였습니다. 쿠폰번호를 확인하세요";
              this.popupSet.popType = "warn";
              this.popupSet.cancelBtnText = "확인";
            }
          }
        }
      } else {
        this.SET_POPUP(true);
        this.popupSet.title = "쿠폰 등록 실패";
        this.popupSet.content =
          "쿠폰번호는 공백이 될 수 없습니다. 쿠폰번호를 확인하세요";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      }
    },
    goHome() {
      this.$router.push("/").catch(() => {});
    },
    async callPopWithSidePassword() {
      let pop;
      pop = {
        popType: "CheckPasswordOwn",
        title: "비밀번호 확인",
        content: "현재 비밀번호를 입력해주세요.",
        immediatly: true,
      };
      this.$refs.appheader.reloadApiPop(pop);
    },
    async callPopWithSideIssue(subData) {
      // console.log("sub", subData);
      let pop;
      pop = {
        popType: "IssueChild",
        title: "부계정 관리",
        content:
          "발급할 부계정은 <b style='color:#ff0a00'>1" +
          localStorage.getItem("drId") +
          " </b>입니다.\n변경할 부계정의 비밀번호를 입력해주세요.",
        immediatly: true,
        functionValue: subData,
      };
      this.$refs.appheader.reloadApiPop(pop);
    },
    initAuthInfo() {
      // console.log("앱헤더에서 부를 것");
      this.$refs.appheader.calledNav("withDraw");
    },
    listReaded(order) {
      document.querySelector(".tabs__header").childNodes[order].click();
    },
    initPaginationEffect() {
      for (
        let k = 0;
        k < document.querySelector("#pagination").childElementCount;
        k++
      ) {
        // console.log(
        //   "Ds",
        //   document.querySelector("#pagination").childNodes[k].childNodes[0]
        //     .textContent
        // );
        if (
          document
            .querySelector("#pagination")
            .childNodes[k].classList.contains("active")
        ) {
          document
            .querySelector("#pagination")
            .childNodes[k].classList.remove("active");
        }
      }
    },
    async loadList(currentPage, order) {
      this.list = [];
      this.SET_CHILD_LOADER(true);
      // this.initPaginationEffect();
      /*document
        .querySelector("#pagination")
        .childNodes[0].classList.add("active");*/
      /*if (this.listStatus.totalCount >= 1) {
        if (order > this.listStatus.last) {
          order = this.listStatus.last - 1;
        }
        document
          .querySelector("#pagination")
          .childNodes[order].classList.add("active");
      }*/
      const searchOpt = {
        pageNo: currentPage,
        recordsPerPage: this.listStatus.dataOnPage,
      };
      try {
        await this.BRING_USER_COUPON_USABLE_LIST(searchOpt).then(() => {
          // console.log(this.GET_COUPON_LIST);
          this.listStatus.totalCount = this.GET_COUPON_LIST.param.totalCount;
          this.listStatus.currentPage = this.GET_COUPON_LIST.param.pageNo;
          let tempList = JSON.parse(JSON.stringify(this.GET_COUPON_LIST.list));
          setTimeout(() => {
            this.SET_CHILD_LOADER(false);
            this.list = tempList;
            this.preParePagination();
          }, 800);
        });
      } catch (error) {
        console.log("erorr", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = " 리스트를 불러올 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    preParePagination() {
      /**
      this.listStatus.totalCount = 122;
       */
      /** api로 list를 불러온 과정 생략 */
      // console.log("==========================================================");
      // console.log(this.listStatus);
      const totalCount = this.listStatus.totalCount;
      const currentPage = this.listStatus.currentPage;
      // console.log("totalCount", totalCount);
      // console.log("currentPage", currentPage);
      const dataOnPage = this.listStatus.dataOnPage;
      const pageCount = this.listStatus.pageCount;
      const totalPage = Math.ceil(totalCount / dataOnPage);
      const pageGroup = Math.ceil(currentPage / pageCount);
      // console.log("totalPage", totalPage);
      // console.log("pageGroup", pageGroup);
      let last = pageGroup * pageCount;
      if (last > totalPage) {
        last = totalPage;
      }
      let first = last - (pageCount - 1);
      const next = last + 1;
      const prev = first - 1;
      if (totalPage < 1) {
        first = last;
      }
      // console.log("last : " + last);
      // console.log("first : " + first);
      // console.log("next :" + next);
      // console.log("prev :" + prev);
      this.listStatus.totalPage = totalPage;
      this.listStatus.prev = prev;
      this.listStatus.next = next;
      this.listStatus.first = first;
      this.listStatus.last = last;
      this.calculatedPaginationList(first, last);
      /**
       * totalCount: 22
       * currentPage: 1
       * totalPage: 3
       * pageGroup: 1
       * last: 3
       * first: -1
       * next: 4
       * prev: -2
       */
      /** [next]
       *  first -> pageGroup  * dataOnPage + 1
       *  last  -> ((pageGroup + 1) * dataOnPage) - 1
       *  [prev]
       *  first -> ((pageGroup - 2) * dataOnPage) + 1
       *  last  -> ((pageGroup - 1) * dataOnpage) - 1
       *
       */
    },
    calculatedPaginationList(first, last, type) {
      const paginationListArray = [];
      // console.log(first);
      // console.log(last);
      for (let j = first; j <= last; j++) {
        if (j > 0) {
          paginationListArray.push(j);
        }
      }
      for (let i = 0; i <= 5; i++) {
        if (last % 5 !== 0) {
          if (paginationListArray[0] % 5 !== 1) {
            paginationListArray.shift();
          } else {
            this.listStatus.first = paginationListArray[0];
            break;
          }
        }
      }

      this.listStatus.currentPaginationList = paginationListArray;
      if (type === "prev") {
        this.$nextTick((_) => {
          if (this.listStatus.last % 5 !== 0) {
            let num = this.listStatus.last % 5;
            this.listStatus.last = this.listStatus.last + (5 - num);
            this.loadList(this.listStatus.last, 4);
          } else {
            this.loadList(this.listStatus.last, 4);
          }
          for (
            let k = 0;
            k < document.querySelector("#pagination").childElementCount;
            k++
          ) {
            // console.log(
            //   "재료",
            //   document.querySelector("#pagination").childNodes[k].childNodes[0]
            //     .textContent
            // );
            if (
              Number(
                document.querySelector("#pagination").childNodes[k]
                  .childNodes[0].textContent
              ) === Number(this.listStatus.last)
            ) {
              document
                .querySelector("#pagination")
                .childNodes[k].classList.add("active");
            }
          }
        });
      } else if (type === "next") {
        this.loadList(this.listStatus.currentPage, 0);
        document
          .querySelector("#pagination")
          .childNodes[0].classList.add("active");
      } else {
        if (this.list.length > 0) {
          this.$nextTick((_) => {
            const target =
              document.querySelectorAll(".tab-wrapper")[this.currentTab];
            if (target !== undefined) {
              target
                .querySelector("#pagination")
                .childNodes[this.listStatus.currentPage - 1].classList.add(
                  "active"
                );
            }
            // console.log(document.querySelector("#pagination"));
            // console.log((this.listStatus.currentPage % 5) - 1);
            if (this.listStatus.currentPage > 5) {
              if (this.listStatus.currentPage % 5 === 0) {
                document
                  .querySelector("#pagination")
                  .childNodes[4].classList.add("active");
              } else {
                document
                  .querySelector("#pagination")
                  .childNodes[
                    (this.listStatus.currentPage % 5) - 1
                  ].classList.add("active");
              }
            } else {
              document
                .querySelector("#pagination")
                .childNodes[this.listStatus.currentPage - 1].classList.add(
                  "active"
                );
            }
            //강제 active 삽입
          });
        }
      }
    },
    navigatorAct(type) {
      // console.log(type);
      let first = this.listStatus.first;
      let last = this.listStatus.last;
      const totalPage = this.listStatus.totalPage;
      const paginationListArray = [];
      let next = this.listStatus.next;
      let prev = this.listStatus.prev;
      for (let j = first; j <= last; j++) {
        if (j > 0) {
          paginationListArray.push(j);
        }
      }
      this.listStatus.currentPaginationList = paginationListArray;
      this.initPaginationEffect();
      /**
       * first next last
       * 보정 가정
       * */
      if (type === "prev") {
        last = first - 1;
        next = last + 1;
        first = first - 5;
        prev = first - 1;
        /**
         * 11    15
         * 6     10
         * currentPage = first
         * totalCount = 13
         */
        this.listStatus.currentPage = last;
      } else if (type === "next") {
        first = first + 5;
        prev = first - 1;
        if (totalPage > last + 5) {
          last = last + 5;
          next = last + 1;
        } else {
          last = totalPage;
          next = last;
        }
        /**
         * 6    10
         * 11   15
         * currentPage = first
         * totalCount = 13
         */
        this.listStatus.currentPage = first;
      }

      this.listStatus.first = first;
      this.listStatus.last = last;
      this.listStatus.prev = prev;
      this.listStatus.next = next;
      this.calculatedPaginationList(first, last, type);
    },
    listOnOffAction() {
      this.listOnOff = !this.listOnOff;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/scrollObj";
.couponBI {
  background-image: linear-gradient(to right, #313c49 0%, #146f83 165%);
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
}
.search {
  width: 100% !important;
  margin: 0;
  padding: 68px 0;
  background-image: linear-gradient(to right, #313c49 0%, #146f83 165%);
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
  .item {
    max-width: 830px;
    margin: 0 auto;
  }
  .s-title {
    display: flex;
    align-items: center;
    margin: 0 0 30px 0;
    .s-img-wrap {
      margin: 0 15px 0 0;
      img {
      }
    }
    h3 {
      font-family: SUIT;
      font-size: 25px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 27px;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
    }
  }
  .s-action {
    display: flex;
  }
}
.btnPagination {
  &.active {
    border-radius: 3px;
    background-color: #146f83;
    p {
      color: #fff;
    }
  }
}

.coupon-box {
  padding: 50px;
}
.s-input-wrap {
  text-align: left;
  input {
    border: 0;
    padding: 0 15px;
    line-height: 55px;
    width: 100%;
    background-color: #ffffff;
    -moz-user-select: auto;

    -webkit-user-select: auto;

    -ms-user-select: auto;

    user-select: auto;
  }
}
.s-btn-wrap {
  width: 135px;
  height: 55px;
  button {
    font-family: SUIT;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 55px;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    padding: 0;
    border: 0;
    width: inherit;
    background: #ff7334;
    cursor: pointer;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
}
.btnon {
  background: gray !important;
  margin-top: 100px;
}
@media screen and (max-width: 860px) {
  .coupon-reg-col {
    margin-right: 10px;
  }
}

@media screen and (max-width: 600px) {
  .coupon-box {
    padding: 30px;
  }
}
</style>
